import { AuthProviderType } from '../../interface/v1/orgSelector/types';
import { getServices } from '../../infra/commonInitializer';
import ConsentService from '../consentService/ConsentService';
import { Configuration, ConfigurationProvider } from '../JWeb/types';
import { getStratusStack } from './utils';
import bindAllMethods from '../../utils/bindAllMethods';
import { getDataCollectionService } from '../JWeb';
import { AnalyticsServiceInputType } from './types';

export type DataCollectionServiceConfigProps = {
  stack: string;
  authProvider: AuthProviderType;
  telemetryAPIkey?: string;
  valveControllerAPIkey?: string;
};

export class DataCollectionRecorderNative {
  public async init(): Promise<void> {
    // No-operation Class
  }
  public async updateDataCollectionService(): Promise<void> {
    // No-operation Class
  }
  public async setInterfaceDependencies(): Promise<void> {
    // No-operation Class
  }
}

/// Only for Web
export class DataCollectionRecorderWeb {
  private _stack;
  private _telemetryAPIkey;
  private _valveControllerAPIkey;
  private _authProvider: AuthProviderType;
  private _consentService: ConsentService;
  private _isNative = false;
  private _dataCollectionServicePlugin;

  constructor(analyticsProps: AnalyticsServiceInputType) {
    const { isNative, stack, telemetryAPIkey, valveControllerAPIkey } =
      analyticsProps || {};
    this._stack = stack;
    this._telemetryAPIkey = telemetryAPIkey;
    this._valveControllerAPIkey = valveControllerAPIkey;
    this._isNative = isNative || false;
  }

  public async init(): Promise<void> {
    // Retrieve services to be used
    const services = getServices();
    this._consentService = services.consentService;

    bindAllMethods(this);
  }

  public setInterfaceDependencies({
    authProvider
  }: {
    authProvider: AuthProviderType;
  }): void {
    this._authProvider = authProvider;
  }

  private getDataCollectionServiceConfig({
    authProvider,
    stack,
    telemetryAPIkey,
    valveControllerAPIkey
  }: DataCollectionServiceConfigProps): Configuration {
    return {
      stack: getStratusStack(stack),
      authProvider: authProvider,
      telemetryAPIkey: telemetryAPIkey,
      valveControllerAPIkey: valveControllerAPIkey,

      verboseLogging: true, //set to true to see console messages
      useValveControllerFiltering: false, //set to true for filtering using ValveControllerAPI
      useOneTrustFiltering: true,
      preConsentEventAccumulation: false
    };
  }

  public async updateDataCollectionService(): Promise<void> {
    if (this._isNative) return;

    this._dataCollectionServicePlugin = await getDataCollectionService();

    // to keep up-to-date the consent in value store
    await this._consentService.updateOptInGroupsInValueStore();

    // Configure DataCollection Provider
    const dataCollectionServiceConfig: Configuration =
      this.getDataCollectionServiceConfig({
        stack: this._stack,
        authProvider: this._authProvider,
        telemetryAPIkey: this._telemetryAPIkey,
        valveControllerAPIkey: this._valveControllerAPIkey
      });

    const configurationProvider: ConfigurationProvider = {
      configuration: dataCollectionServiceConfig
    };

    this._dataCollectionServicePlugin?.setConfigurationProvider(
      configurationProvider
    );
  }
}
