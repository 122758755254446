import matchPath from '../../../utils/matchPath';
import { internalLogger } from '../../../interface/v1/logger';
import EventService from '../../../services/eventService';
import { INavigationService } from '../../../services/navigationService';
import { Location } from 'history';

export default class EventsBehaviors {
  private _eventService: EventService;
  private _navigationService: INavigationService;

  constructor(options: {
    eventService: EventService;
    navigationService: INavigationService;
  }) {
    this._eventService = options.eventService;
    this._navigationService = options.navigationService;
  }

  public init(): void {
    this._navigationEvents();
  }

  private _navigationEvents() {
    // Triggerers
    const shellUrlChanged = () => {
      // TODO: I have no idea how this works before Navigation init() call.
      let previousLocation = this._navigationService.location;

      window.addEventListener('popstate', () => {
        const navigation = this._navigationService;
        const currentLocation: Location = navigation.location;

        const eventData = {
          currentLocation,
          previousLocation
        };

        this._eventService.publish(
          this._eventService.eventNames.shellUrlChanged,
          eventData
        );

        internalLogger?.log?.('url-changed: ', eventData);

        previousLocation = currentLocation;
      });
    };

    // Listeners
    const shellCallInterfaceNavigationPush = () => {
      this._eventService.subscribe(
        this._eventService.eventNames?.shellCallInterfaceNavigationPush,
        (event) => {
          const navigation = this._navigationService;

          let path = event?.eventData?.properties?.servicePath;

          const isSamePath = matchPath(navigation.location.pathname, {
            exact: true,
            pathToCompare: path
          });

          if (
            isSamePath &&
            event?.eventData?.properties?.keepQueryParamsInSamePath
          ) {
            const newUrl = new URL(
              (path + navigation.location.search)
                .replace(/[?]/g, '&') // replace all ? with &
                .replace('&', '?'), // replace first & with ?
              window.location.origin
            );

            path = newUrl.pathname + `?${newUrl.searchParams.toString()}`;
          }

          internalLogger?.log?.(
            `pushing-to-path: ${path} from: ${navigation.location.pathname}`
          );

          navigation?.push?.(path);
        }
      );
    };

    shellUrlChanged();
    shellCallInterfaceNavigationPush();
  }
}
