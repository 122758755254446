import { getServices } from '../../infra/commonInitializer';
import { InterfacesType } from '../../interface';
import { isNative as isNativeFromJWeb } from '../../services/JWeb';
import InitializeDeprecatedServices from './initializeDeprecatedServices';
import { StorageLocale, localeSessionRepository } from './storageLocale';
import UseLocaleContext from './useLocaleContext';
import UseLoggedInRules from './useLoggedInRules';
import InitBackgroundTaskManager from './InitBackgroundTaskManager';

export default class AfterInitBehaviors {
  public static init = async function (
    interfaces: InterfacesType
  ): Promise<void> {
    const isNative = await isNativeFromJWeb();
    const { orgSelector } = interfaces.v1;

    const {
      tenantHandlerService,
      sessionService,
      navigationService,
      localizationService,
      webServiceRouting,
      backgroundTaskManagerService
    } = getServices();

    const useLoggedInRules = new UseLoggedInRules({
      navigationService,
      sessionService,
      tenantHandlerService
    });

    const useLocaleContext = new UseLocaleContext({
      navigationService,
      localizationService,
      webServiceRouting
    });

    const initBackgroundTaskManager = new InitBackgroundTaskManager({
      backgroundTaskManagerService
    });

    initBackgroundTaskManager.init();

    await useLocaleContext.init();

    !isNative &&
      (await StorageLocale?.init?.({
        navigationService,
        localizationService,
        localeSessionRepository
      }));

    await InitializeDeprecatedServices?.init?.({
      sessionService,
      orgSelector,
      isNative
    });
    await useLoggedInRules?.init?.();
  };
}
