import valueFunctionQueryParam from './valueFunctionQueryParam';
import * as T from '../types';

const valueFunctions = {
  queryParam: valueFunctionQueryParam
};

type ValueFunctionsUnsanitizedType = typeof valueFunctions;
export type ValueFunctionsType = {
  [key in keyof ValueFunctionsUnsanitizedType]: T.CheckValueFunctionType<
    Parameters<ValueFunctionsUnsanitizedType[key]>[0],
    Parameters<ValueFunctionsUnsanitizedType[key]>[1]
  >;
};

export default valueFunctions as ValueFunctionsType;
