import { InterfacesInitializerStatePropsType } from '../../../interface/types';

export type LogType = any;

export type LogFuncType = (...args: LogType[]) => void;

export type CreateLoggerInstanceOptionsType = {
  preffixLog?: LogType;
};

export type CreateLoggerOptionsType = CreateLoggerInstanceOptionsType & {
  type: EnumAllowedLogType;
};

export type CreateLoggerType = (
  options: CreateLoggerOptionsType & LoggerInputType
) => LogFuncType;

export type CreateLoggerInstanceType = (
  options?: CreateLoggerInstanceOptionsType
) => Record<EnumAllowedLogType, LogFuncType>;

export type SetLocalOptionsType = (options: LoggerInputType) => void;

export type RemoveLocalOptionsType = () => void;

export type LoggerType = Record<EnumAllowedLogType, LogFuncType> & {
  createLoggerInstance: CreateLoggerInstanceType;
  setLocalOptions: SetLocalOptionsType;
  removeLocalOptions: RemoveLocalOptionsType;
};

export interface LoggerInterfaceType {
  [key: string]: LogFuncType;
  createLoggerInstance: CreateLoggerInstanceType;
  setLocalOptions: SetLocalOptionsType;
  removeLocalOptions: RemoveLocalOptionsType;
}

export type ServiceRoutingInitializerStatePropsType =
  InterfacesInitializerStatePropsType;

export enum EnumAllowedLogType {
  'debug' = 'debug',
  'error' = 'error',
  'log' = 'log',
  'warn' = 'warn'
}

export type LoggerAllowedTypes = { [key in EnumAllowedLogType]?: boolean };

export type LoggerInputType = {
  enable?: boolean;
  allowedExpressions?: string[];
  allowedTypes?: LoggerAllowedTypes;
};
