import { jshellLoggerPropsLocalStorageKey } from '../../../config/localStorageKeys';
import {
  setItemLocalStorage,
  getItemLocalStorage,
  removeItemLocalStorage
} from '../../../utils/toBeRemoved/localStorage';
import { getJWeb, isNative, JWebEnums } from '../../../services/JWeb';
import { JWebType } from '../../../services/JWeb/types';
import * as T from './types';

/* internal variables */
let loggerInput: T.LoggerInputType;
let isNativeInfo: boolean;
let jweb: JWebType;

const types: T.EnumAllowedLogType[] = Object.values(T.EnumAllowedLogType);

const _loggerForNative = (type: T.EnumAllowedLogType, arrayValue: any[]) => {
  const jwebConsole = jweb?.Plugins?.Console;

  const logLevel = (() => {
    switch (type) {
      case 'debug':
        return JWebEnums.LogLevel.Debug;
      case 'error':
        return JWebEnums.LogLevel.Error;
      case 'warn':
        return JWebEnums.LogLevel.Warn;
      case 'log':
        return JWebEnums.LogLevel.Log;
    }
  })();

  const message = (() => {
    let result = '';
    arrayValue?.forEach((value) => {
      if (typeof value === 'string') {
        result += ' ' + value;
      } else {
        try {
          result += ' ' + JSON.stringify(value);
        } catch (error) {
          console.error(error);
        }
      }
    });
    return result;
  })();

  jwebConsole.log({
    logLevel,
    message,
    tag: ''
  });
};

const _consoleApi = (type: T.EnumAllowedLogType, arrayValue: any[]) => {
  if (isNativeInfo) {
    _loggerForNative(type, arrayValue);
  } else {
    console?.[type]?.(...arrayValue);
  }
};

const _getLoggerOptions = (): T.LoggerInputType => {
  const loggerProps = getItemLocalStorage(jshellLoggerPropsLocalStorageKey);

  if (loggerProps) {
    return loggerProps;
  }

  return loggerInput || {};
};

const _isValidExpressions = (
  allowedExpressions: string[],
  ...args: Parameters<T.LogFuncType>
): boolean => {
  if (allowedExpressions) {
    return allowedExpressions.some((expression) =>
      args.some((arg) => {
        return typeof arg === 'string' && new RegExp(expression).test(arg);
      })
    );
  }
  return true;
};

const _createLogger: T.CreateLoggerType = ({ type, preffixLog }) => {
  return (...args) => {
    const { allowedTypes, allowedExpressions, enable } = _getLoggerOptions();

    const logs = [...args];
    if (preffixLog) logs.unshift(preffixLog);

    if (enable) {
      try {
        if (
          _isValidExpressions(allowedExpressions, ...logs) &&
          allowedTypes?.[type]
        ) {
          _consoleApi(type, logs);
        }
      } catch (err) {
        if (allowedTypes?.error) {
          const withPreffixLog = preffixLog ? preffixLog + ' = ' : '';
          console?.['error']?.(withPreffixLog + err.toString());
        }
      }
    }
  };
};

export const createLoggerInstance: T.CreateLoggerInstanceType = (options) => {
  const loggers: T.LoggerType = {} as any;

  types?.forEach((type) => {
    loggers[type] = _createLogger({ ...options, type });
  });
  return loggers;
};

const setLocalOptions: T.SetLocalOptionsType = (options) => {
  setItemLocalStorage(jshellLoggerPropsLocalStorageKey, options);
};

const removeLocalOptions: T.RemoveLocalOptionsType = () => {
  removeItemLocalStorage(jshellLoggerPropsLocalStorageKey);
};

const publicMethods = {
  createLoggerInstance,
  setLocalOptions,
  removeLocalOptions
};

const defaultLoggerInstance: T.LoggerType = {
  ...createLoggerInstance(),
  ...publicMethods
};

export const internalLogger = createLoggerInstance({
  preffixLog: 'JSHELL__@jarvis__shell-commons'
});

export default async function logger(
  loggerDataInput: T.LoggerInputType
): Promise<T.LoggerInterfaceType> {
  loggerInput = loggerDataInput;
  jweb = await getJWeb();
  isNativeInfo = await isNative();
  return defaultLoggerInstance;
}

export function createNoopLoggerInterface(): T.LoggerInterfaceType {
  return {
    createLoggerInstance: function (): Record<T.EnumAllowedLogType, any> {
      console.debug('Function not implemented.');
      return { debug: '', error: '', warn: '', log: '' };
    },
    setLocalOptions: function (): void {
      console.debug('Function not implemented.');
    },
    removeLocalOptions: function (): void {
      console.debug('Function not implemented.');
    }
  };
}
