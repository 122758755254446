import EventService from '../../../../../../services/eventService';
import { getServices } from '../../../../../../infra/commonInitializer';
import {
  EventServiceCallBackType,
  ListenerHandlerType
} from '../../../../../../services/eventService/types';

class EventManager {
  private _eventService: EventService;

  constructor() {
    const services = getServices();
    this._eventService = services?.eventService;
  }

  public subscribeToEvent(
    eventName: string,
    callback: EventServiceCallBackType
  ): Promise<ListenerHandlerType> {
    return this._eventService.subscribe(eventName, callback);
  }

  public sendOnboardingFailedToLaunchEvent(): void {
    this._eventService.publish('onboardingAgentFailedToLaunch', undefined);
  }

  public sendWebOnboardingStageStartedEvent(
    appSessionId: string,
    serviceId?: string
  ): void {
    this._eventService.publish(
      this._eventService.eventNames.webOnboardingStageStarted,
      {
        appSessionId,
        serviceId
      }
    );
  }

  public sendOnboardingStageFinishedEvent(
    appSessionId: string,
    serviceId: string,
    resultData: {
      result: 'success' | 'failed' | 'cancelled';
      output?: Record<string, any>;
      xCorrelationId: string;
    },
    xCorrelationId: string
  ): void {
    this._eventService.publish(
      this._eventService.eventNames.webOnboardingStageFinished,
      {
        appSessionId,
        serviceId,
        result: {
          result: resultData?.result,
          xCorrelationId
        }
      }
    );
  }
}
export default EventManager;
