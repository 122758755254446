import EventNames from '../../../config/eventNames';
import { getServices } from '../../../infra/commonInitializer';
import EventService from '../../../services/eventService';
import * as T from './types';

export default class EventInterface implements T.EventsInterfaceType {
  private _eventService: EventService;
  public shellEventNames: typeof EventNames;
  constructor() {
    const services = getServices();
    this._eventService = services.eventService;
    this.shellEventNames = this._eventService.eventNames;
  }

  private _removeListenersMapType: T.RemoveListenersMapType = new Map();

  addEventListener(name: string, action: T.EventCallBackType): void {
    const listenerHandler = this._eventService.subscribe(name, action);

    const actionMap = this._getActionMap(name);

    actionMap.set(action, listenerHandler);
  }

  private _getActionMap(name: string) {
    let actionMap = this._removeListenersMapType.get(name);
    if (!actionMap) {
      actionMap = new Map();
      this._removeListenersMapType.set(name, actionMap);
    }

    return actionMap;
  }

  removeEventListener(name: string, action: T.EventCallBackType): void {
    const actionMap = this._getActionMap(name);
    const listenerHandlerPromise = actionMap.get(action);
    actionMap.delete(action);

    listenerHandlerPromise.then((listenerHandler) =>
      listenerHandler.unsubscribe()
    );
  }

  triggerEvent(name: string, event: T.EventValueType): void {
    this._eventService.publish(name, event);
  }
}

export function createNoopEventInterface(): T.EventsInterfaceType {
  return {
    shellEventNames: undefined,
    addEventListener: function (): void {
      console.debug('Function not implemented.');
    },
    removeEventListener: function (): void {
      console.debug('Function not implemented.');
    },
    triggerEvent: function (): void {
      console.debug('Function not implemented.');
    }
  };
}
