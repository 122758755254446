import Criterion from '.';
import {
  AccessControlInterfaceType,
  AccessControlOptionsType
} from '../accessControl/types';
import {
  EntitlementsInterfaceType,
  OfferingAndEntitlementType,
  OfferingAndEntitlementOptionsType
} from '../entitlements/types';
import { GrantsInterfaceType } from '../grants/types';
import { ILocalizationService } from '../../../services/localizationService';
import { EventsInterfaceType } from '../events/types';
import { ISessionService } from '../../../services/session';
import { AuthContextEnum } from '../../../services/authTokenService';
import { IFeatureFlagService } from '../../../services/featureFlagService';
import { IUserService } from '../../../services/userService';
import { GrantOptionsType, GrantType } from '../../../services/grants/types';
import ITenantHandlerService from '../../../services/tenantHandler/ITenantHandlerService';
import valueFunctions from './valueFunctions';
import { internalLogger } from '../../../interface/v1/logger';
import { CriterionInputType } from '../../../infra/commonInitializer/types';
import { ServiceLocalizationLanguageType } from '../../../services/localizationService/types';

type CreateCriterionSingletonPropsType = {
  interfaces: {
    entitlements: EntitlementsInterfaceType;
    accessControl: AccessControlInterfaceType;
    grants: GrantsInterfaceType;
  };
  services: {
    events: EventsInterfaceType;
    sessionService: ISessionService;
    tenantHandler: ITenantHandlerService;
    featureFlagService: IFeatureFlagService;
    userService: IUserService;
    localization: ILocalizationService;
  };
  criterions: CriterionInputType<any>[];
};

let criterionSingleton: ReturnType<typeof _createCriterion>;

function _createCriterion(options: CreateCriterionSingletonPropsType) {
  const { accessControl, entitlements, grants } = options.interfaces;
  const {
    events,
    sessionService,
    tenantHandler,
    featureFlagService,
    userService,
    localization
  } = options?.services || {};

  const criterionInterface = new Criterion({
    valueFunctions: {
      // @ts-ignore
      entitlement: async (
        value: OfferingAndEntitlementType,
        options?: OfferingAndEntitlementOptionsType
      ) => !!(await entitlements.checkEntitlements([value], options)),
      // @ts-ignore
      offering: async (
        value: OfferingAndEntitlementType,
        options?: OfferingAndEntitlementOptionsType
      ) => !!(await entitlements.checkOfferings([value], options)),
      // @ts-ignore
      scope: async (value: string, options?: AccessControlOptionsType) => {
        let hasScopes = false;
        const loggedIn = !!sessionService.isLoggedIn();
        if (loggedIn) {
          hasScopes = !!(await accessControl.checkScopes(
            [{ scope: value }],
            options
          ));
        }
        return hasScopes;
      },
      isLoggedIn: async (value: boolean) => {
        const isLoggedIn = !!sessionService?.isLoggedIn?.();

        return value ? isLoggedIn : !isLoggedIn;
      },
      authContext: async (value: { context: AuthContextEnum }) => {
        const authContext = value?.context;

        if (!authContext) return false;

        const currentAuthContext = tenantHandler.getCurrentContext();

        return authContext === currentAuthContext;
      },
      featureFlag: async (value: {
        clientKey: string;
        featureFlag: string;
        value: unknown;
        defaultValue: unknown;
      }) => {
        const client = await featureFlagService.getClient(value?.clientKey);
        const featureFlag = await client.getFeatureFlag({
          key: value?.featureFlag,
          defaultValue: value?.defaultValue
        });
        return featureFlag === value?.value;
      },
      userDetails: async (value: { countries: string[] }) => {
        const country = await userService.getCountry();
        return value?.countries
          ?.map((c) => c.toLocaleLowerCase())
          ?.includes(country);
      },
      locale: async (value: { languages: ServiceLocalizationLanguageType }) => {
        return !!(await localization.checkAllowedLanguages(value?.languages));
      },
      // @ts-ignore
      grant: async (value: GrantType, options?: GrantOptionsType) => {
        return !!(await grants.checkGrants([value], options));
      },
      // TODO: this should be fixed ASAP
      // @ts-ignore
      pendingGrant: async (value: GrantType, options?: GrantOptionsType) => {
        // @ts-ignore
        return !!(await grants.checkPendingGrants([value], options));
      },
      tenant: async (value: {
        type?: string[];
        roleCategory?: string;
        countries?: string[];
        regionId?: string;
      }) => {
        try {
          const tenant = tenantHandler.getTenantByContext(
            AuthContextEnum.tenant
          );

          let checkRoleCategory = true;
          let checkTenantType = true;
          let checkTenantCountries = true;
          let checkTenantRegionId = true;

          if (value?.roleCategory) {
            checkRoleCategory = !!(
              value?.roleCategory?.toLowerCase() ===
              tenant?.data?.roleCategory?.toLowerCase()
            );
          }

          if (value?.type) {
            checkTenantType = !!value?.type
              ?.map((t) => t.toLowerCase())
              ?.includes(tenant?.data?.type?.toLowerCase());
          }

          if (value?.countries) {
            const { countries } =
              await tenantHandler.getCurrentTenantAdditionalData();
            checkTenantCountries = countries.some((country) =>
              value.countries?.includes(country)
            );
          }

          if (value?.regionId) {
            const { regionId } =
              await tenantHandler.getCurrentTenantAdditionalData();
            checkTenantRegionId = value.regionId === regionId;
          }

          return (
            checkRoleCategory &&
            checkTenantType &&
            checkTenantCountries &&
            checkTenantRegionId
          );
        } catch (error) {
          internalLogger?.error(
            'The tenant criteria is poorly formatted ',
            error
          );
          return false;
        }
      },
      // @ts-ignore
      programInfo: async (
        value: { programLevel: string; state?: string },
        options?: OfferingAndEntitlementOptionsType
      ) => {
        const programInfo = await entitlements.getPlanInfo(options);

        if (value?.state && value?.programLevel) {
          return (
            programInfo?.programLevel == value.programLevel &&
            programInfo?.state == value.state
          );
        } else if (value?.state) {
          return programInfo?.state == value?.state;
        } else if (value?.programLevel) {
          return programInfo?.programLevel == value?.programLevel;
        }

        return false;
      },

      ...valueFunctions
    },
    criterionList: options?.criterions as any,
    eventInterface: events,
    eventList: [
      events.shellEventNames.shellAccessControlChangedEventName,
      events.shellEventNames.shellEntitlementChangedEventName,
      events.shellEventNames.shellUserLogedInEventName,
      events.shellEventNames.shellUserLogedOutEventName,
      events.shellEventNames.shellFeatureFlagChangedEventName,
      events.shellEventNames.shellGrantsChangedEventName,
      events.shellEventNames.shellPendingGrantsChangedEventName,
      events.shellEventNames.shellTenantChangedEventName,
      events.shellEventNames.shellUrlChanged
    ],
    eventName: events.shellEventNames.shellCriterionChangedEventName
  });

  criterionInterface.addValueFunctions({
    criterion: async (value: string) => {
      return criterionInterface.checkByCriterionKey(value);
    }
  });

  return criterionInterface;
}

export function getCriterionSingleton() {
  return criterionSingleton;
}

export default function initializeCriterionSingleton(
  options: CreateCriterionSingletonPropsType
) {
  if (criterionSingleton) return criterionSingleton;

  criterionSingleton = _createCriterion(options);

  return criterionSingleton;
}
