import {
  CreateSubscriberOptions,
  EventInfo,
  EventServiceErrorType,
  EventServicePluginError,
  SubscribeOptions,
  SubscriptionHandle,
} from '@jarvis/jweb-core';
import { validateIdentifier } from '../../utils/ValidateIdentifier';
import { eventServicePluginErrorMethod } from '../../utils/exceptions';
import { ErrorMessages } from '../../utils/ErrorMessages';
import { eventHub } from '../EventHub';
import { Subscriber as EventSubscriber } from '../../web';
import { Subscription } from './Subscription';

// Represents subscriber for the events
export const Subscriber = (options?: CreateSubscriberOptions): EventSubscriber => {
  let subscriptions: Subscription[] = [];
  const events: any[] = [];
  let paused: any = null;

  // The method used to subscribe to an event.
  const subscribe = async (
    options: SubscribeOptions,
    eventHandler: (eventInfo: EventInfo) => void
  ): Promise<SubscriptionHandle | EventServicePluginError> => {
    if (options.eventName && !validateIdentifier(options.eventName)) {
      return eventServicePluginErrorMethod(EventServiceErrorType.invalidIdentifier, ErrorMessages.invalidIdentifier);
    }
    if (options.publisherId && !validateIdentifier(options.publisherId)) {
      return eventServicePluginErrorMethod(EventServiceErrorType.invalidIdentifier, ErrorMessages.invalidIdentifier);
    }
    try {
      const subscription = new Subscription(
        { subscribe, unsubscribe, pauseEvents, resumeEvents, events, isPaused },
        eventHandler,
        options.publisherId,
        options.eventName,
      );
      const subscriptionHandle: SubscriptionHandle = {
        publisherId: options.publisherId,
        eventName: options.eventName,
        isActive: true,
        unsubscribe: async (): Promise<void | EventServicePluginError> => {
          eventHub.unsubscribe(subscription);
        }
      };
      eventHub.subscribe(subscription, options.eventName, options.publisherId);

      subscriptions.push(subscription);
      console.log('Subscriber: subscribe()');
      console.log(`Subscriptions: ${subscriptions}`);
      return subscriptionHandle;
    } catch (e) {
      console.error(e);
      return eventServicePluginErrorMethod(EventServiceErrorType.unexpectedError, ErrorMessages.unexpectedError);
    }
  };

  // The method used to unsubscribe from an event.
  const unsubscribe = async (
    eventName?: string,
    publisherId?: string
  ): Promise<void | EventServicePluginError> => {
    if (eventName && !validateIdentifier(eventName)) {
      return eventServicePluginErrorMethod(EventServiceErrorType.invalidIdentifier, ErrorMessages.invalidIdentifier);
    }
    if (publisherId && !validateIdentifier(publisherId)) {
      return eventServicePluginErrorMethod(EventServiceErrorType.invalidIdentifier, ErrorMessages.invalidIdentifier);
    }
    try {
      const subsToRemove: Subscription[] = [];
      subscriptions.forEach(sub => {
        if ((!eventName && !publisherId) ||
          (!publisherId && eventName && sub.eventName === eventName) ||
          (!eventName && publisherId && sub.publisherId === publisherId) ||
          (eventName && publisherId && sub.eventName === eventName && sub.publisherId === publisherId)) {
          eventHub.unsubscribe(sub);
          subsToRemove.push(sub);
        }
      });

      subsToRemove.forEach(sub => {
        subscriptions = subscriptions.filter(item => item !== sub);
      });
      console.log('Subscriber: unsubscribe()');
      console.log(`Subscriptions: ${subscriptions}`);
      return;
    } catch (e) {
      console.error(e);
      return eventServicePluginErrorMethod(EventServiceErrorType.unexpectedError, ErrorMessages.unexpectedError);
    };
  };

  const pauseEvents = async () => {
    if (!options?.isPersistent) {
      return eventServicePluginErrorMethod(EventServiceErrorType.subscriberNotPersistent, ErrorMessages.subscriberNotPersistent);
    }
    console.log('pausing the event');
    if (!paused) {
      paused = true;
      console.log('subscriptions', subscriptions);
    } else {
      console.log('subscriber is already in paused state');
    }
  };

  const resumeEvents = async () => {
    if (!options?.isPersistent) {
      return eventServicePluginErrorMethod(EventServiceErrorType.subscriberNotPersistent, ErrorMessages.subscriberNotPersistent);
    }
    if (paused) {
      console.log('resuming the event');
      paused = false;
      subscriptions.forEach((sub) => {
        eventHub.resumeEvent(sub);
      });
    } else {
      console.log('subscriber is not in paused state');
    }
  };

  const isPaused = () => paused;

  return {
    subscribe,
    unsubscribe,
    pauseEvents,
    resumeEvents,
    events,
    isPaused
  };
};
