import { getAccessPolicies } from '../../clients/shell/getAccessPolicies';
import { UserContextEnum } from '../../interface/types';
import { IAuthProviderService } from '../authProviderService';
import { IAuthTokenService } from '../authTokenService';
import userContextEnumToAuthContextEnum from '../authTokenService/utils/userContextEnumToAuthContextEnum';
import { SetServiceDependencies } from '../../infra/commonInitializer/types';
import { IScopeService } from './IScopeService';
import { ScopeRepository } from './ScopeRepository';
import { Scope } from './types';
import IApplicationService from '../applicationService/IApplicationService';

export class ScopeService implements IScopeService {
  private _sessionTokensExist = false;
  private _scopeRepository: ScopeRepository;
  private _authProviderService: IAuthProviderService;
  private _authTokenService: IAuthTokenService;
  private _applicationService: IApplicationService;

  public setDependencies({ services, repositories }: SetServiceDependencies) {
    const {
      sessionService,
      authProviderService,
      authTokenService,
      applicationService
    } = services;
    const { scopeRepository } = repositories;

    this._scopeRepository = scopeRepository;
    this._authProviderService = authProviderService;
    this._authTokenService = authTokenService;
    this._applicationService = applicationService;

    this._sessionTokensExist = !!sessionService.isLoggedIn();
  }

  public hasCachedScopes(options): boolean {
    const repositoryKey = this.getRepositoryKey(options);
    const result = this._scopeRepository.findOne(repositoryKey);
    return result?.length > 0;
  }

  public checkScopes(scopesToCheck, options): boolean {
    if (!this._sessionTokensExist) return false;

    const repositoryKey = this.getRepositoryKey(options);
    const scopes = this._scopeRepository.findOne(repositoryKey);
    return scopesToCheck?.every?.((requestedScope) =>
      scopes?.some?.(
        (cachedScope: Scope) => requestedScope.scope === cachedScope.scope
      )
    );
  }

  // TODO: Refactor to work with AuthContextEnum instead of userContextEnum when AuthContextEnum authProvider exists
  private async getAccessPoliciesScopes(userContextString): Promise<Scope[]> {
    const userContext = userContextString
      ? UserContextEnum[userContextString]
      : null;
    const response = await getAccessPolicies(
      this._authProviderService.createAuthProviderByUserContextEnum(
        userContext
      ),
      this._applicationService
    );
    return (
      response?.scopes.map((scopeName) => {
        return {
          scope: scopeName
        };
      }) || []
    );
  }

  public async updateScopes(options): Promise<boolean> {
    if (!this._sessionTokensExist) return false;

    const repositoryKey = this.getRepositoryKey(options);
    const cachedScopes: Scope[] = this._scopeRepository.findOne(repositoryKey);
    // TODO: Refactor to work with AuthContextEnum instead of userContextEnum
    const newScopes = await this.getAccessPoliciesScopes(options?.userContext);
    const sameSize = newScopes?.length === cachedScopes?.length;
    const isEqual =
      sameSize && newScopes.toString() === cachedScopes.toString();
    if (!isEqual) {
      this._scopeRepository.save(repositoryKey, newScopes);
      return true;
    }
    return false;
  }

  // TODO: Need to be removed in the future and this service should work only with AuthContextEnum
  private getRepositoryKey(options): string {
    const userContext = options?.userContext;
    const authContext = userContextEnumToAuthContextEnum(userContext);
    return this._authTokenService.getSuffix(authContext);
  }
}
