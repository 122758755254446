import bindAllMethods from '../../utils/bindAllMethods';
import { shellVisitUuidCookieName } from '../../config/constants';
import { OPT_IN_GROUP_VALUE } from './AnalyticsService';
import { createCDMEvent, filterQueryParams } from './AnalyticsEventBuilder';
import { getCookie } from '../../utils/cookies';
import { EventCategoryEnum } from './AnalyticsEventEnums';
import IAnalyticsService from './IAnalyticsService';

import { ReservedValueStoreKeys } from '../JWeb/JWebEnums';
import { setCookie } from '../../utils/cookies';
import { UuidV4 } from '../../utils/crypto';
import { writeDataToValueStore } from '../JWeb';
import EventService from '../eventService';
import { registerOptanonDataLayerEventHandler } from '../consentService/optanonUtils';
import { SetServiceDependencies } from '../../infra/commonInitializer/types';
import { UserActivityInputType } from '../IdleService/types';

/** This is a sub service inside the Analytics Service that works in Web & Native. */
export default class VisitUuidHandler {
  private _analyticsService: IAnalyticsService;
  private _eventService: EventService;
  private _isNative;
  private _userActivityIdleTimeInSeconds;
  private _unblockVisitUUID;
  private _hasVisitUUIDCookie = false;

  public isVisitUUIDReady = new Promise((resolve, reject) => {
    this._unblockVisitUUID = { resolve, reject };
  });

  private _analyticsOptInGroup = OPT_IN_GROUP_VALUE;

  constructor(isNative: boolean, userActivity: UserActivityInputType) {
    this._isNative = isNative;
    this._userActivityIdleTimeInSeconds =
      userActivity?.idle?.time / 1000 || 1800; //fallback (30 minutes);
  }

  public setDependencies({ services }: SetServiceDependencies): void {
    this._eventService = services.eventService;
    this._analyticsService = services.analyticsService;

    bindAllMethods(this);
  }

  /*
     Use Case #3 for Analytics Service.

     The User receives a visitUUID when initialized, but it is only sent when consent is gained (via de VisitContext message).
  */
  public async init(): Promise<void> {
    this._hasVisitUUIDCookie = !!getCookie(shellVisitUuidCookieName, false);

    if (!this._hasVisitUUIDCookie) {
      await this.createAndSetVisitUUID();
      this._unblockVisitUUID.resolve();
      this.sendVisitContextRaw();
    } else {
      this._unblockVisitUUID.resolve();
      const hasWhitelist = this.checkWhiteList();
      if (hasWhitelist) {
        this.sendVisitContextRaw();
      }
    }
    this.subscribeEvents();
  }

  public checkWhiteList(): boolean {
    const queryParams = new URLSearchParams(window.location.search);
    const hasAttributionParameters =
      !!filterQueryParams(queryParams).whiteListed.length;
    return hasAttributionParameters;
  }

  /**
   * When created a new visitUUID, this callback sends VisitContextRaw event.
   */
  private async handleJshellUserActivityOnActiveEvent() {
    await this.createAndSetVisitUUID();
    this.sendVisitContextRaw();
  }

  /*
    Use case #4: We update the visitUUID after back from idleness.
    This callback function will be executed.
  */
  public subscribeEvents(): void {
    this._eventService.subscribe(
      'jshell-useractivity-onactive',
      this.handleJshellUserActivityOnActiveEvent
    );
  }

  /**
   * Publish the notification for Visit Context Raw via Analytics when called.
   */
  public publishVisitContextRawEvent(): void {
    const eventList = [createCDMEvent(EventCategoryEnum.visitContextRaw)];
    this._analyticsService.publishCdmEvents(eventList);
  }

  /**
   * VisitContextRaw contains visitUUID + cokkies + queryParams
   */
  public async sendVisitContextRaw(): Promise<void> {
    //https://hp-jira.external.hp.com/browse/JSHELL-2915
    // Rule: adding to banner queue only becasue isn't necessary
    // create a new visit UUID

    if (!this._isNative) {
      // The new visitUUID will be sent via analytics Service
      const sendVisitContextRawOnOptIn = {
        event: 'pb.onOptInGroups',
        callback: this.publishVisitContextRawEvent,
        groups: this._analyticsOptInGroup
      };

      registerOptanonDataLayerEventHandler(sendVisitContextRawOnOptIn);
    }

    if (this._isNative) {
      this.publishVisitContextRawEvent();
    }
  }

  /**
   * It creates and adds a newShellVisitUUID to Cookies (for browser) and in
   * ValueStore (on Jweb).
   */
  public async createAndSetVisitUUID(): Promise<void> {
    // Rule: Is necessary a create a new visit UUID before adding in banner
    // Optanon Data Layer.

    const newShellVisitUUID = UuidV4.getRandomUUID();

    // TODO: shellVisitUuidCookieName Could be in a Repository in Local Storage.

    // setting for Browser
    setCookie(
      shellVisitUuidCookieName,
      newShellVisitUUID,
      this._userActivityIdleTimeInSeconds.toString(),
      false
    );

    // setting for JWeb
    await writeDataToValueStore([
      {
        key: ReservedValueStoreKeys.visitUuid,
        value: newShellVisitUUID
      }
    ]);
  }
}
