import { EventServicePluginError } from '../JWeb/types';
import { internalLogger } from '../../interface/v1/logger';

import { Stack } from '../JWeb/JWebEnums';

export function hasErrorInEventServicePublisher(
  publisher: any | EventServicePluginError
): publisher is EventServicePluginError {
  if (publisher === undefined) {
    internalLogger?.error('Publisher not available.');
    return true;
  }
  if (publisher.errorType === undefined) {
    return false;
  }
  internalLogger?.error('Publisher with error: ', publisher);
  return true;
}

export const getStratusStack = (_stack: string): Stack => {
  if (_stack === 'prod') {
    return Stack.production;
  } else if (_stack === 'stage') {
    return Stack.staging;
  } else if (['local', 'dev', 'pie'].includes(_stack)) {
    return Stack.pie;
  }
};
