import { DataValveCDMEvent } from './types';
import {
  EventSchemaVersionEnum,
  EventCategoryGunEnum,
  EventCategoryEnum
} from './AnalyticsEventEnums';

function createCDMEvent(
  eventCategory: EventCategoryEnum,
  eventData?: any
): DataValveCDMEvent {
  return createEventEnvelope(eventCategory, eventData);
}

function createEventEnvelope(
  eventCategory: EventCategoryEnum,
  eventData: any
): DataValveCDMEvent {
  const newISODate = new Date().toISOString();
  const eventingEventSchemaVersion = EventSchemaVersionEnum.eventingEvent;
  return {
    dateTime: newISODate,
    eventDetailType: EventCategoryGunEnum[eventCategory],
    eventCategory: eventCategory,
    version: eventingEventSchemaVersion,
    eventDetail: createEventDetail(eventCategory, eventData)
  };
}

function createEventDetail(eventCategory: EventCategoryEnum, eventData: any) {
  let eventDetail: any = {};

  switch (eventCategory) {
    case EventCategoryEnum.visitContextRaw:
      eventDetail = createVisitContextRawEventDetail();
      break;
    case EventCategoryEnum.simpleUi:
      eventDetail = eventData;
      break;
  }
  return eventDetail;
}

function createVisitContextRawEventDetail() {
  const referrer = document.referrer;
  const hostname = window.location.hostname;
  const visitContextRawSchemaVersion = EventSchemaVersionEnum.visitContextRaw;
  const queryParams = new URLSearchParams(window.location.search);
  const filteredQueryParams = filterQueryParams(queryParams);

  return {
    referrer: referrer,
    url: hostname,
    ...(filteredQueryParams.whiteListed.length && {
      urlParams: filteredQueryParams.whiteListed.join('&')
    }),
    ...(filteredQueryParams.blackListed.length && {
      urlParamsRedacted: filteredQueryParams.blackListed.join('&')
    }),
    version: visitContextRawSchemaVersion
  };
}

function filterQueryParams(queryParams: URLSearchParams): {
  whiteListed: string[];
  blackListed: string[];
} {
  const whitelistParams = [
    'fbclid',
    'gclid',
    'dclid',
    'gclsrc',
    'MSCLKID',
    'utm_content',
    'utm_term',
    'utm_campaign',
    'utm_medium',
    'utm_source',
    '_ga',
    'mc_cid',
    'mc_eid',
    '_bta_tid',
    '_bta_c',
    'trk_contact',
    'trk_msg',
    'trk_module',
    'trk_sid',
    'gdfms',
    'gdftrk',
    'gdffi',
    '_ke',
    'redirect_log_mongo_id',
    'redirect_mongo_id',
    'sb_referer_host',
    'mkwid',
    'pcrid',
    'ef_id',
    's_kwcid',
    'msclkid',
    'dm_i',
    'epik',
    'campaignid',
    'adplacement',
    'jumpid'
  ];

  const whiteListed = [];
  const blackListed = [];

  queryParams.forEach((value, key) =>
    whitelistParams.includes(key)
      ? whiteListed.push(`${key}=${value}`)
      : blackListed.push(`${key}=${'_redacted_'}`)
  );

  return { whiteListed, blackListed };
}

export { createCDMEvent, filterQueryParams };
