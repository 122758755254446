import * as internals from './internals';
import { getServices } from '../../../infra/commonInitializer';
import UserActivityInterface from './UserActivityInterface';
import {
  UserActivityInterfaceType,
  initializeUserActivityInterfaceType
} from './types';

let userActivityInterface: UserActivityInterface;

export function getUserActivityInterface(): UserActivityInterface {
  return userActivityInterface;
}

export async function initializeUserActivityInterface(
  options: initializeUserActivityInterfaceType
): Promise<UserActivityInterfaceType> {
  if (userActivityInterface) return userActivityInterface.getInterface();

  const { idleService } = getServices();
  const { events } = options;

  userActivityInterface = new UserActivityInterface({
    idleService
  });

  // TODO should be a jshell use case beside that events should be a service instead interface.
  userActivityInterface.start({
    onActive: () => internals.handleOnActive(events), // callback function to be executed after back form idleness
    onIdle: () => internals.handleOnIdle(events), // callback function to be executed after idle time
    onHide: () => internals.handleOnHide(events), // callback function to be executed when window become hidden
    onShow: () => internals.handleOnShow(events) // callback function to be executed when window become visible
  });

  return userActivityInterface.getInterface();
}

export function createNoopUserActivityInterface(): UserActivityInterfaceType {
  return {
    isIdle: function (): boolean {
      console.debug('Function not implemented.');
      return false;
    }
  };
}
