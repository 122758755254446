type OnboardingDirectorBackgroundTaskHttpMethod = 'GET' | 'PUT' | 'POST';

export type ServiceOnboardingTriggerType = {
  path: string;
  onboardingContext: OnboardingDirectorContextType;
  localizationContextKey?: string;
  layoutKey?: string | false;
  criterionKey?: string;
  fallbackKeys?: {
    failedToLaunch: string;
  };
  tenantHandlerOverride?: string;
};

export type OnboardingDirectorContextType =
  | {
      experience: string;
      entry: string;
      bizModelHint: string;
      entryUrl?: string;
      programLeve?: string;
      offerHint?: string;
    }
  | Record<string, unknown>;

export type OnboardingDirectorAppType = {
  clientId: string;
  appVersion?: string;
  osType: string;
  osVersion: string;
  countryRegionIso?: string;
  locale?: string;
  container: string;
};

export type OnboardingDirectorDeviceType = {
  modelName?: string;
  deviceName?: string;
  identity: {
    type: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    resource: Record<string, any>;
  };
  shadow: {
    bizModel: string;
  };
};

export type OnboardingDirectorSessionResponseType = {
  sessionContext: {
    sessionId: string;
    onboardingContext: OnboardingDirectorContextType;
    app: OnboardingDirectorAppType;
    device?: OnboardingDirectorDeviceType;
    xCorrelationId?: string;
    createdAt?: string;
    updatedAt?: string;
  };
  nextService: {
    serviceId?: string;
    resultUrl?: string;
    exitUrl?: string;
    resultIndex?: number;
  };
  backgroundTaskRequests?: OnboardingDirectorSessionTasksResponseType[];
};

export type OnboardingDirectorSessionTasksResponseType = {
  backgroundTaskRequestId: string;
  resultUrl: string;
  task: {
    taskId: string;
    arguments: {
      request: {
        method: OnboardingDirectorBackgroundTaskHttpMethod;
        path: string;
        sessionId: string;
        headers?: Record<string, any>;
      };
    };
  };
};

export enum SortByEnum {
  createdAtAsc = 'createdAtAsc',
  createdAtDesc = 'createdAtDesc',
  updatedAtAsc = 'updatedAtAsc',
  updatedAtDesc = 'updatedAtDesc'
}

export type GetRequestOptionsType = {
  data: {
    onboardingContext: OnboardingDirectorContextType;
    app: OnboardingDirectorAppType;
    limit?: number;
    sortBy?: SortByEnum;
  };
};

export type PostRequestOptionsType = {
  data: {
    onboardingContext: OnboardingDirectorContextType;
    app: OnboardingDirectorAppType;
    device?: OnboardingDirectorDeviceType;
  };
};

export type PutRequestOptionsType = {
  baseURL: string;
  data: {
    result: 'success' | 'failed' | 'cancelled';
    output?: Record<string, any>;
    xCorrelationId: string;
  };
};

export type PutRequestBackgroundRequestsType = {
  baseURL: string;
  data: {
    result: {
      method: OnboardingDirectorBackgroundTaskHttpMethod;
      path: string;
      sessionId: string;
      statusCode: number;
      headers?: Record<string, any>;
      body: {
        data: string;
        isBase64Encoded: boolean;
        contentType: string;
      };
    };
  };
};
