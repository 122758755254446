import authV2 from '../v2/auth';
import accessControlImport from './accessControl';
import initializeAnalyticsInterface from './analytics';
import auth from './auth';
import initializeCriterionSingleton from './Criterion/singleton';
import entitlementsImport from './entitlements';
import Fallback from './Fallback';
import Layout from './Layout';
import initializeLocalizationInterface from './localization';
import loggerImport from './logger';
import initializeMicrofrontendRouterInterface from './MicrofrontendRouter/initializeMicrofrontendRouterInterface';
import optimizely from './optimizely';
import OrgSelector from './orgSelector';
import serviceRoutingImport from './serviceRouting';
import Store from './store';
import initializeTenantHandlerInterfaceSingleton from './TenantHandler';
import { initializeUserActivityInterface } from './userActivity';
import userSessionImport from './userSession';
import EventInterface from './events';
import Breadcrumb from './breadcrumb';
import initializeUserInterfaceV1 from './userInterface';
import initializeSessionInterfaceV1 from './sessionInterface';
import initializeFeatureFlagInterfaceV1 from './featureFlags';
import initializeAppInterfaceV1 from './app';
import { getServices, getRepositories } from '../../infra/commonInitializer';
import initializeMonitoringInterfaceV1 from './monitoring';
import initializeThemeInterface from './theme';
import initializeServiceWorkerInterface from './serviceWorker';
import initializeNavigationInterface from './navigation';
import initializeGrantsInterfaceV1 from './grants';
import { ShellInterfaceV1Type } from './types';
import { CommonsInitializeInterfaceType } from '../../infra/commonInitializer/types';
import initializeGraphQLInterfaceV1 from './graphql';
import initializeRoutesInterface from './routes';

export default async function initializeShellInterfaceV1(
  commonsInitializeInterfaces: CommonsInitializeInterfaceType
): Promise<ShellInterfaceV1Type> {
  const services = getServices();
  const repositories = getRepositories();
  const {
    appContext,
    applicationService,
    authTokenService,
    breadcrumbService,
    featureFlagService,
    scopeService,
    sessionService,
    tenantHandlerService,
    supportSessionService,
    monitoringService,
    userService,
    localizationService,
    navigationService
  } = services;

  const { entitlementRepository } = repositories;

  const {
    accessControl: accessControlInput,
    adapter: adapterInput,
    analytics: analyticsInput,
    breadcrumb: breadcrumbInput,
    criterions: criterionsInput,
    entitlements: entitlementsInput,
    fallbackConfiguration,
    localization: localizationInput,
    logger: loggerInput,
    login: loginInput,
    navigation: navigationInput,
    onboarding: onboardingInput,
    optimizely: optimizelyInput,
    stack
  }: CommonsInitializeInterfaceType = commonsInitializeInterfaces;

  // TODO: Should get its service via getServices method.
  const appInterface = await initializeAppInterfaceV1({
    applicationService
  });

  //Initializing auth provider
  const authProvider = await auth(loginInput);
  const authProviderV2 = authV2(loginInput);

  // Initialize services
  authProvider._setSessionService(sessionService);
  authProvider._setTenantHandlerService(tenantHandlerService);
  authProvider._setNavigationService(navigationService);

  authProviderV2._setSessionService(sessionService);
  authProviderV2._setTenantHandlerService(tenantHandlerService);
  authProviderV2._setNavigationService(navigationService);

  //Initializing other interfaces

  const eventsInterface = new EventInterface();

  const storeInterface = new Store();

  const userActivityInterface = await initializeUserActivityInterface({
    events: eventsInterface
  });

  optimizely(optimizelyInput);

  const analyticsInterface = await initializeAnalyticsInterface({
    isEnabled: analyticsInput.enabled
  });

  // TODO we'll remove all this code when interfaces won't be necessary anymore.
  services?.analyticsService?.setInterfaceDependencies({
    authProvider,
    store: storeInterface
  });
  await services?.analyticsService?.init();
  await services?.consentService?.init();

  const navigationInterface = await initializeNavigationInterface({
    useLegacyNavigation: navigationInput.useLegacyNavigation
  });

  const localizationInterface = await initializeLocalizationInterface();

  const accessControlInterface = await accessControlImport({
    accessControl: accessControlInput,
    appContext,
    store: storeInterface,
    events: eventsInterface,
    scopeService
  });

  const entitlementsInterface = await entitlementsImport({
    authProvider: authProviderV2,
    entitlements: entitlementsInput,
    store: storeInterface,
    events: eventsInterface,
    sessionService,
    appContext,
    authTokenService,
    repository: entitlementRepository
  });

  const orgSelector = await OrgSelector({
    onboarding: onboardingInput,
    authProvider: authProviderV2.createOrgedAuthProvider(),
    orglessAuthProvider: authProviderV2.createOrglessAuthProvider(),
    navigation: navigationInterface,
    localization: localizationInterface,
    sessionService,
    tenantHandlerService,
    userService
  });

  const featureFlagsInterface = await initializeFeatureFlagInterfaceV1({
    featureFlagService
  });

  const serviceRouting = serviceRoutingImport({
    onboardingInput
    // store: storeInterface
  });

  const loggerInterface = await loggerImport(loggerInput);

  const grantsInterface = await initializeGrantsInterfaceV1();

  const criterionInterface = initializeCriterionSingleton({
    interfaces: {
      accessControl: accessControlInterface,
      entitlements: entitlementsInterface,
      grants: grantsInterface
    },
    services: {
      events: eventsInterface,
      sessionService,
      tenantHandler: tenantHandlerService,
      featureFlagService,
      userService,
      localization: localizationService
    },
    criterions: criterionsInput
  });

  // TODO we'll remove all this code when interfaces won't be necessary anymore.
  services?.routesService?.setInterfaceDependencies({ criterionInterface });

  const userSession = userSessionImport();

  const fallbackData = {
    event: {
      eventInterface: eventsInterface,
      eventName: 'shell-fallback'
    },
    navigationInterface: navigationInterface,
    configuration: fallbackConfiguration
  };
  const fallback = new Fallback(fallbackData);

  const layout = new Layout();

  await breadcrumbService.start({
    enable: breadcrumbInput?.enable,
    settingsList: breadcrumbInput?.settingsList,
    defaultSettingsKey: breadcrumbInput?.defaultSettingsKey,
    navigation: navigationInterface,
    criterion: criterionInterface
  });

  const breadcrumbsInterface = new Breadcrumb();

  const microfrontendRouter = adapterInput?.disableRouter
    ? undefined
    : initializeMicrofrontendRouterInterface({
        interfaces: {
          events: eventsInterface,
          navigation: navigationInterface,
          store: storeInterface,
          fallback,
          criterion: criterionInterface,
          layout,
          userSession,
          authProvider,
          localization: localizationInterface,
          sessionService,
          tenantHandler: tenantHandlerService,
          monitoringService,
          userService
        },
        localization: localizationInput,
        onboarding: onboardingInput,
        //@ts-ignore
        stack: stack
      });

  // TODO: Change to start the service before the interface.
  microfrontendRouter?.start?.();

  const routesInterface = await initializeRoutesInterface();

  const tenantHandlerInterface =
    await initializeTenantHandlerInterfaceSingleton();

  const userInterface = await initializeUserInterfaceV1();

  const graphQLInterface = await initializeGraphQLInterfaceV1();

  const sessionInterface = await initializeSessionInterfaceV1();

  const monitoringInterface = await initializeMonitoringInterfaceV1();

  const themeInterface = await initializeThemeInterface();

  const serviceWorkerInterface = await initializeServiceWorkerInterface();

  return {
    app: appInterface,
    userInterface,
    sessionInterface,
    sessionService,
    authProvider,
    analytics: analyticsInterface,
    events: eventsInterface,
    navigation: navigationInterface,
    store: storeInterface,
    featureFlags: featureFlagsInterface,
    localization: localizationInterface,
    orgSelector,
    theme: themeInterface,
    serviceRouting,
    logger: loggerInterface,
    userActivity: userActivityInterface,
    entitlements: entitlementsInterface,
    accessControl: accessControlInterface,
    criterion: criterionInterface,
    userSession,
    fallback,
    microfrontendRouter,
    tenantHandlerInterface,
    tenantHandler: tenantHandlerService,
    authToken: authTokenService,
    breadcrumbs: breadcrumbsInterface,
    routes: routesInterface,
    supportSessionService,
    monitoring: monitoringInterface,
    serviceWorker: serviceWorkerInterface,
    userService,
    grants: grantsInterface,
    graphql: graphQLInterface
  };
}
