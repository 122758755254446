import { EventInfo } from '@jarvis/jweb-core';
import { Subscriber } from '../../web';
// Represents a subscription to an event.
export class Subscription {
  subscriber: Subscriber;
  publisherId?: string;
  eventName?: string;
  isActive = false;

  private eventHandler: ((eventInfo: EventInfo) => void);

  constructor(
    subscriber: Subscriber,
    eventHandler: (eventInfo: EventInfo) => void,
    publisherId?: string,
    eventName?: string,
    isActive = false,
  ) {
    this.subscriber = subscriber;
    this.eventHandler = eventHandler;
    this.publisherId = publisherId;
    this.eventName = eventName;
    this.isActive = isActive;
  }

  notify(eventInfo: EventInfo): void {
    console.log('Subscription received notification for eventName');
    console.log(`${eventInfo.eventName}', publisherId: '${eventInfo.publisherId}`);
    this.eventHandler(eventInfo);
  }
}
