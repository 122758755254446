import bindAllMethods from '../../../utils/bindAllMethods';
import matchPath from '../../../utils/matchPath';
import * as T from './types';

export default class Fallback implements T.FallbackInterfaceType {
  private fallbackList: T.FallbackConfigurationType['fallbackList'];
  private state: T.FallbackStateType;
  private eventInterface: T.FallbackConstructorOptionsType['event']['eventInterface'];
  private navigationInterface: T.FallbackConstructorOptionsType['navigationInterface'];
  private startedListerns = false;
  defaultFallback: T.FallbackStrategyObjectType;
  eventName: T.FallbackConstructorOptionsType['event']['eventName'];

  constructor(options: T.FallbackConstructorOptionsType) {
    bindAllMethods(this);
    this.eventName = options?.event?.eventName;
    this.eventInterface = options?.event?.eventInterface;
    this.fallbackList = options?.configuration?.fallbackList;
    this.navigationInterface = options?.navigationInterface;

    this.handleDefaultFallback(options);

    this.startListeners();
  }

  private handleDefaultFallback(options) {
    if (options?.configuration?.defaultFallbackKey) {
      this.defaultFallback = this.getFallbackByKey(
        options?.configuration?.defaultFallbackKey
      );
    }
    if (!this.defaultFallback && options?.configuration?.defaultFallback) {
      this.defaultFallback = options?.configuration?.defaultFallback;
    }
  }

  private startListeners() {
    if (!this.startedListerns) {
      this.startedListerns = true;

      const urlListener = () => {
        const currentPath = this.navigationInterface.location.pathname;
        const currentFallback = this.getCurrentFallback();
        const fallback =
          this.getFallbackByPath(currentPath) ||
          currentFallback ||
          this.defaultFallback;

        const fallbackProperties = this.getFallbackPropertiesFromQueryParam();

        const isFallbackPath = (() => {
          if (fallback?.strategy === 'loadAsset' && fallback?.path) {
            return matchPath(currentPath, {
              pathToCompare: fallback?.path
            });
          }
          return false;
        })();

        if (!isFallbackPath) {
          this.clearFallbackState();
        } else {
          this.setCurrentFallback(fallback, { fallbackProperties });
        }
      };

      window.addEventListener('popstate', urlListener);
      urlListener();
    }
  }

  private getFallbackPropertiesFromQueryParam(): T.FallbackPropertiesType {
    const urlSearchParams = new URLSearchParams(window.location.search);

    const fallbackProperties: T.FallbackPropertiesType = {};

    urlSearchParams.forEach((value, key) => {
      if (value && key) {
        fallbackProperties[key] = value;
      }
    });

    return fallbackProperties;
  }

  getFallbackByKey(key: string): T.FallbackLoadAssetStrategyType {
    if (key) {
      return this.fallbackList?.find?.(
        (fallbackDescription) => fallbackDescription?.key === key
      );
    }
  }

  getDefaultRootFallback(): T.FallbackRedirectStrategyType {
    return this.defaultFallback as T.FallbackRedirectStrategyType;
  }

  getFallbackByPath(path: string): T.FallbackLoadAssetStrategyType {
    const isFallbackPath = (fallbackPath) => {
      if (fallbackPath) {
        return matchPath(fallbackPath, {
          exact: true,
          pathToCompare: path
        });
      }
    };

    // Avoid isse to load defaultFallback if path is repeated on list
    const defaultFallback =
      this.defaultFallback?.strategy === 'loadAsset'
        ? this.defaultFallback
        : undefined;
    if (isFallbackPath(defaultFallback?.path)) {
      return defaultFallback;
    } else {
      return this.fallbackList?.find?.((fallbackDescription) =>
        isFallbackPath(fallbackDescription?.path)
      );
    }
  }

  getCurrentFallback(): T.FallbackStateType {
    return this.state;
  }

  listen(callback: (state: T.FallbackStateType) => void): () => void {
    const callbackProxy = async () => {
      callback?.(this.getCurrentFallback());
    };

    this.eventInterface?.addEventListener?.(this.eventName, callbackProxy);

    return () =>
      this.eventInterface?.removeEventListener?.(this.eventName, callbackProxy);
  }

  useReactHook(React: any): T.FallbackLoadAssetStrategyType {
    const [state, setState] = React.useState(this.getCurrentFallback());

    React.useEffect(() => {
      const removeListener = this.listen((value) => setState(value));

      return () => removeListener();
    }, []);

    return state;
  }

  clearFallbackState(): void {
    this.setCurrentFallback(undefined);
  }

  setCurrentFallback(
    fallback?: T.FallbackStrategyObjectType,
    options?: T.SetCurrentFallbackOptionsType
  ): void {
    let newFallback: T.FallbackStateType = undefined;
    let redirect: string;

    if (fallback?.strategy === 'redirect') {
      newFallback = undefined;
      redirect = fallback.redirectTo;
    } else if (fallback?.strategy === 'loadAsset') {
      newFallback = {
        ...fallback,
        fallbackProperties: options?.fallbackProperties
      };
      redirect = fallback.path;
    }

    if (JSON.stringify(this.state) !== JSON.stringify(newFallback)) {
      this.state = newFallback;
      this.eventInterface.triggerEvent(this.eventName, this.state);
    }

    if (
      redirect &&
      !matchPath(redirect, {
        exact: true,
        pathToCompare: this.navigationInterface.location.pathname
      })
    ) {
      this.navigationInterface.push(redirect);
    }
  }

  setCurrentFallbackByKey(
    key: string,
    options?: T.SetCurrentFallbackOptionsType
  ): void {
    const fallback = this.getFallbackByKey(key) || this.defaultFallback;

    this.setCurrentFallback(fallback, options);
  }
}

export function createNoopFallbackInterface(): T.FallbackInterfaceType {
  return {
    defaultFallback: undefined,
    eventName: '',
    getFallbackByKey: function (): any {
      console.debug('Function not implemented.');
    },
    getFallbackByPath: function (): any {
      console.debug('Function not implemented.');
    },
    getDefaultRootFallback(): any {
      console.debug('Function not implemented.');
    },
    getCurrentFallback: function (): any {
      console.debug('Function not implemented.');
    },
    listen: function (): any {
      console.debug('Function not implemented.');
    },
    useReactHook: function (): any {
      console.debug('Function not implemented.');
    },
    clearFallbackState: function (): void {
      console.debug('Function not implemented.');
    },
    setCurrentFallback: function (): void {
      console.debug('Function not implemented.');
    },
    setCurrentFallbackByKey: function (): void {
      console.debug('Function not implemented.');
    }
  };
}
